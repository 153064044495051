$themes: (
  default: (
    alternateRowMask: rgba(255, 255, 255, 0.2),
    borderColour: rgba(255, 255, 255, 0.35),
    buttonBackground: #ff9f17,
    filterHighlight: #fffacd,
    formText: #24c2ce,
    gradientEnd: #24c2ce,
    gradientStart: #21cb94,
    highlight: #fdcc57,
    inputBackground: #fff,
    footerBackground: #3c3d41,
    formError: #f00,
    leftGradientEnd: #45d3c0,
    leftGradientStart: #4fd5b2,
    link: #24c2ce,
    linkRowMask: rgba(255, 255, 255, 0.3),
    mainText: #fff,
    mobileNavHover: #f4f4f4,
    mobileSelectedText: #fab822,
    mobileUnselectedText: #787878,
    mobileUnselectedSecondaryText: #5a5a5a,
    modalBackground: #23c7b1,
    modalShadow: 0 0 25px #fff,
    progressBackground: #dadada,
    progressForeground: #ff9f17,
    rightGradientEnd: #41d0cb,
    rightGradientStart: #43d2c0,
    secondaryText: #21cb94,
    separator: #91e3d8,
    splashTextBackground: #d3d3d3,
    splashText: #000,
    statusText: #ff0,
    tertiaryText: #c8f1e7,
    unselectedText: #fff,
  ),
  high-contrast: (
    alternateRowMask: rgba(255, 255, 255, 0.12),
    borderColour: rgba(255, 255, 255, 0.35),
    buttonBackground: #ff9f17,
    filterHighlight: #fffacd,
    formText: #13646e,
    gradientEnd: #13646e,
    gradientStart: #11665a,
    highlight: #fdcc57,
    inputBackground: #fff,
    footerBackground: #3c3d41,
    formError: #f00,
    leftGradientEnd: #388779,
    leftGradientStart: #388779,
    link: #13646e,
    linkRowMask: rgba(255, 255, 255, 0.2),
    mainText: #fff,
    mobileNavHover: #f4f4f4,
    mobileSelectedText: #fab822,
    mobileUnselectedText: #787878,
    mobileUnselectedSecondaryText: #5a5a5a,
    modalBackground: #126165,
    modalShadow: 0 0 25px #fff,
    progressBackground: #dadada,
    progressForeground: #ff9f17,
    rightGradientEnd: #388779,
    rightGradientStart: #388779,
    secondaryText: #24a790,
    separator: #91e3d8,
    splashTextBackground: #d3d3d3,
    splashText: #000,
    statusText: #ff0,
    tertiaryText: #c8f1e7,
    unselectedText: #fff,
  ),
);

@mixin body() {
  @each $theme, $map in $themes {
    &.theme-#{$theme},
    &.theme-#{$theme} .main {
      background-image: linear-gradient(
        90deg,
        map-get($map, 'gradientStart'),
        map-get($map, 'gradientEnd')
      );
      color: map-get($map, 'mainText');
      min-height: 100vh;
    }
  }
}

@mixin theme($property, $key) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $value: map-get($map, $key);
      #{$property}: #{$value};
    }
  }
}

@mixin gradient() {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      background-image: linear-gradient(
        90deg,
        map-get($map, 'gradientStart'),
        map-get($map, 'gradientEnd')
      );
    }
  }
}

@mixin lighterGradient($amount) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      background-image: linear-gradient(
        90deg,
        lighten(map-get($map, 'gradientStart'), $amount),
        lighten(map-get($map, 'gradientEnd'), $amount)
      );
    }
  }
}

@mixin darkerGradient($amount) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      background-image: linear-gradient(
        90deg,
        darken(map-get($map, 'gradientStart'), $amount),
        darken(map-get($map, 'gradientEnd'), $amount)
      );
    }
  }
}

@mixin customGradient($start, $end) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      background-image: linear-gradient(
        90deg,
        map-get($map, $start),
        map-get($map, $end)
      );
    }
  }
}
