@import 'mixins';
@import 'themes';

/* =================
    CONSTANT STYLES
   ================= */

body {
  @include body();
  font-size: 16px;
  text-align: left;
}

html {
  body,
  button,
  input,
  select,
  textarea {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
  }
}

.main-content {
  padding-bottom: 20px;
}

a {
  @include theme('color', 'mainText');
}

input[type='text'],
input[type='password'],
select,
textarea {
  border: 1px solid;
  @include theme('border-color', 'formText');
  border-radius: 4px;
  box-sizing: border-box;
  @include theme('color', 'formText');
  padding: 6px 8px;

  &::placeholder {
    @include theme('color', 'formText');
  }
}

select {
  appearance: none;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAIBAMAAAA/ygPCAAAAIVBMVEUAAAAkws4kws4kws4kws4kws4kws4kws4kws4kws4kws7VZpObAAAACnRSTlMAmRHMZu4ziFUi+GdIPAAAADhJREFUCNdjYHFgYGBPYOhawsAgFcmgvMqBcZUAA5PVEqmFDAxA7ioBIMUWtViBAQhSjRhAgAmIASTICPN/RzzZAAAAAElFTkSuQmCC')
    no-repeat 98% 50%;
  @include theme('background-color', 'mainText');
  box-sizing: border-box;
  height: 33px;
  padding: 0 8px;
}

input[type='checkbox'] {
  height: 15px;
  width: 15px;
}

.error,
.success {
  @include theme('color', 'statusText');
  font-weight: 400;

  a {
    @include theme('color', 'statusText');
  }
}

/* ===============
    HELPER STYLES
   =============== */

.clearfix {
  *zoom: 1;

  &:after {
    clear: both;
  }

  &:before,
  &:after {
    content: ' ';
    display: table;
  }
}
