@import '../themes';
@import '../mixins';

footer {
  @include content-container(10px, 0px, 0px);

  @include theme('background-color', 'footerBackground');
  box-sizing: border-box;
  height: 20px;
  bottom: 0;
  font-size: 12px;
  line-height: 20px;
  position: fixed;
  transform: translateZ(0); // Needed to stop element from jumping in Chrome
  z-index: 1;

  @include small {
    padding: 0 10px;
  }

  &:before {
    @include theme('background-color', 'footerBackground');
  }

  .copyright {
    float: left;
  }

  .legal {
    float: right;

    a {
      margin-left: 10px;
      text-decoration: none;
    }
  }
}

.morph-banner {
  @include theme('background-color', 'formError');
  bottom: 20px;
  font-size: 20px;
  left: 0;
  padding: 5px 0;
  position: fixed;
  right: 0;
  text-align: center;
}
