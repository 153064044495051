$minFullSizeWidth: 1200px;
$maxWideDesktopWidth: 1199px;
$minWideDesktopWidth: 960px;
$maxDesktopWidth: 959px;
$minDesktopWidth: 768px;
$maxTabletWidth: 767px;
$minTabletWidth: 568px;
$maxWidePhoneWidth: 567px;
$minWidePhoneWidth: 480px;
$maxPhoneWidth: 479px;
$minPhoneWidth: 0px;

// These are used for Hall of Fame styles
$desktopHeaderHeight: 71px;
$mobileHeaderHeight: 60px;
$desktopFilterSectionHeight: 157px;
$mobileFilterSectionHeight: 39px;
$breadcrumbsHeight: 34px;
$bannerHeight: 37px;
$fullSizeTop: $desktopHeaderHeight + $desktopFilterSectionHeight;
$wideDesktopTop: $desktopHeaderHeight + $desktopFilterSectionHeight;
$desktopTop: $desktopHeaderHeight + $mobileFilterSectionHeight;
$smallTop: $mobileHeaderHeight + $mobileFilterSectionHeight;

@mixin fullSize {
  @media (min-width: $minFullSizeWidth) {
    @content;
  }
}

@mixin wideDesktop {
  @media (min-width: $minWideDesktopWidth) and (max-width: $maxWideDesktopWidth) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $minDesktopWidth) and (max-width: $maxDesktopWidth) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $minTabletWidth) and (max-width: $maxTabletWidth) {
    @content;
  }
}

@mixin widePhone {
  @media (min-width: $minWidePhoneWidth) and (max-width: $maxWidePhoneWidth) {
    @content;
  }
}

@mixin phone {
  @media (min-width: $minPhoneWidth) and (max-width: $maxPhoneWidth) {
    @content;
  }
}

@mixin extraLarge {
  @include fullSize {
    @content;
  }

  @include wideDesktop {
    @content;
  }
}

@mixin large {
  @include fullSize {
    @content;
  }

  @include wideDesktop {
    @content;
  }

  @include desktop {
    @content;
  }
}

@mixin small {
  @include tablet {
    @content;
  }

  @include widePhone {
    @content;
  }

  @include phone {
    @content;
  }
}

@mixin phones {
  @include widePhone {
    @content;
  }

  @include phone {
    @content;
  }
}

@mixin content-container($oneSidePadding, $topPadding, $bottomPadding) {
  box-sizing: border-box;
  width: 100%;

  @include fullSize {
    $contentWidth: calc(((100% - 1200px) / 2) + #{$oneSidePadding});
    padding: #{$topPadding} #{$contentWidth} #{$bottomPadding} #{$contentWidth};
  }

  @include wideDesktop {
    $contentWidth: calc(((100% - 960px) / 2) + #{$oneSidePadding});
    padding: #{$topPadding} #{$contentWidth} #{$bottomPadding} #{$contentWidth};
  }

  @include desktop {
    $contentWidth: calc(((100% - 768px) / 2) + #{$oneSidePadding});
    padding: #{$topPadding} #{$contentWidth} #{$bottomPadding} #{$contentWidth};
  }

  @include tablet {
    $contentWidth: calc(((100% - 568px) / 2) + #{$oneSidePadding});
    padding: #{$topPadding} #{$contentWidth} #{$bottomPadding} #{$contentWidth};
  }

  @include widePhone {
    $contentWidth: calc(((100% - 480px) / 2) + #{$oneSidePadding});
    padding: #{$topPadding} #{$contentWidth} #{$bottomPadding} #{$contentWidth};
  }

  @include phone {
    $contentWidth: calc(((100% - 320px) / 2) + #{$oneSidePadding});
    padding: #{$topPadding} #{$contentWidth} #{$bottomPadding} #{$contentWidth};
  }
}

@mixin header-top() {
  @include fullSize {
    top: 71px;
  }

  @include wideDesktop {
    top: 71px;
  }

  @include desktop {
    top: 71px;
  }

  @include tablet {
    top: 60px;
  }

  @include widePhone {
    top: 60px;
  }

  @include phone {
    top: 60px;
  }
}
