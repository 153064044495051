@import '../mixins';
@import '../themes';

.Collapsible {
  margin-bottom: 1px;
  width: 100%;

  .Collapsible__trigger,
  .Collapsible__contentInner {
    border: 1px solid;
    @include theme('border-color', 'mainText');
    padding: 14px 15px;
  }

  .Collapsible__trigger {
    cursor: pointer;
    display: block;
    font-size: 22px;
    transition: background-color linear 0.4s;

    &:before {
      border-style: solid;
      border-width: 3px 3px 0 0;
      content: '';
      display: inline-block;
      height: 0.45em;
      left: -5px;
      margin: 1px;
      position: relative;
      transform: rotate(45deg);
      width: 0.45em;

      display: inline-block;
      margin-right: 10px;
      transition: transform 0.25s;
    }

    &.is-open {
      &:before {
        transform: rotate(135deg);
        transform-origin: 75% 40%;
      }
    }

    &.is-closed {
      @include theme('background-color', 'mainText');
      @include theme('color', 'secondaryText');
    }
  }

  .Collapsible__contentInner {
    border-top: none;
  }
}
